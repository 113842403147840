import React, { Component, Suspense } from 'react';
import { connect } from "react-redux"

import '../../assets/css/style.css'
import '../../assets/css/custom.css'

const NavbarPage = React.lazy(() => import('../../components/Navbar'));
const Payment = React.lazy(() => import('./payment'))
const PhoneVerified = React.lazy(() => import('./phone-verified'))
const Step = React.lazy(() =>import('./step/opening-step'))

const PrioritasMember = React.lazy(() => import('./prioritas-member'))
const ProfileMember = React.lazy(() => import('./prioritas-member/profile-member'))
const DocumentMember = React.lazy(() => import('./prioritas-member/document-member'))
const ConsutationMember = React.lazy(() => import('./prioritas-member/consultation-member'))
const MappingMember = React.lazy(() => import('./prioritas-member/mapping-member'))
const AmbassadorMember = React.lazy(() => import('./prioritas-member/ambassador-member'))
const HistoryPayment = React.lazy(() => import('./prioritas-member/history-payment'))
const OurFinance = React.lazy(() => import('./prioritas-member/our-finance'))

const TalentMapping = React.lazy(() => import('./talent-mapping'))

const Footer = React.lazy(() => import('../../components/Footer'));

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" }
            ],
            navItemsDashboard : [
                { id: 2 , idnm : "document-member", navheading: "Document" },
                { id: 3 , idnm : "consultation-member", navheading: "Consultation" },
                { id: 4 , idnm : "mapping-member", navheading: "Talent Mapping" },
                { id: 5 , idnm : "ambassador-member", navheading: "Ambassador" },
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "darkheader", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }
 
    render() {
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >
                    <NavbarPage navItems={this.props.data.user.finish_form === 0 || this.props.data.status_payment === false ? this.state.navItems : this.state.navItemsDashboard} navclassName={this.state.navClass}/>
                    {
                    this.props.data.user.is_phone_verified === 0 ? 
                        <PhoneVerified/>
                        :
                       <>
                        {
                        this.props.data.status_payment === false ?
                            <Payment/>
                            :
                            <>
                            {
                            this.props.data.user.finish_form === 0 ?
                                <Step/>
                                :
                                <>
                                {
                                this.props.data.member_type_pm === true ?
                                <>
                                    <PrioritasMember/>
                                    <ProfileMember/>
                                    <DocumentMember/>
                                    <HistoryPayment/>
                                    <ConsutationMember/>
                                    <MappingMember/>
                                    <AmbassadorMember/>
                                    <OurFinance/>
                                </>
                                :
                                    <TalentMapping/>
                                }
                                </>
                            }
                            </>
                        } 
                        </>
                    }  
                        <Footer/>
                     </Suspense>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.auth.dataUser,
})

export default connect(mapStateToProps, null)(HomePage);