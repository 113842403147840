import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { useSearchParams } from 'react-router-dom'

import API_URL from "../../Api"
import logo from '../../assets/images/logo-black.png'

const EmailVerified = () => {

    const[status,setStatus] = useState('') 
    const [searchParams] = useSearchParams()

    const verified = () => {
        let formData = new FormData()
        formData.append('token',searchParams.get('token'))
        API_URL.membershipUrl.post('check-email-verified',formData)
        .then(response => {
            setStatus(response.data.message)
            console.log(response.data.message)
        })
    }

    useEffect(() => {
        verified()
    },[])

    return(
        <>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <center><img src={logo} className='img-login' alt='images'/></center>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="alert alert-secondary" role="alert">{status}</div>
                                </div>
                            </div>
                            <center><Link to='/logout' className="btn btn-secondary mt-2">Back to Login</Link></center>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailVerified;