import axios from 'axios'

// const url = 'http://103.41.205.87:80/api/v1/application';
// const urlMembership = 'http://103.41.205.87:80/membership';
// const urlStudent = 'http://103.41.205.87:80/student/'

const url = 'https://superstudent.niecindonesia.com/api/v1/application';
const urlMembership = 'https://superstudent.niecindonesia.com/membership';
const urlStudent = 'https://superstudent.niecindonesia.com/student/';

const baseUrl =  axios.create({
    baseURL: url,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const membershipUrl =  axios.create({
    baseURL: urlMembership,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer OiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY1MDA5NDQyMCwia`
    }
})

const studentUrl =  axios.create({
    baseURL: urlStudent,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer jM0NTY3ODkwIiwibmFtZSI6IkpvaG4IiwiaWF0INTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4f`
    }
})

const API_URL = {
    baseUrl,
    membershipUrl,
    studentUrl
}

export default API_URL