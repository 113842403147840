import React, { useState } from "react"
import { Link } from "react-router-dom"
import API_URL from "../../Api"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSearchParams } from 'react-router-dom'

import '../login/login-style.css'
import logo from '../../assets/images/logo-black.png'


const ChangePassword = () => {

    const[alert,setAlert] = useState({status:false,message:''})
    const[button, setButton] = useState({ textLogin:'Ubah Password', disabledBtn:false})

    const [searchParams] = useSearchParams()

    const schema = yup.object({
        password: yup.string().required('password wajib diisi').min(6, 'Minimal 6 karakter'),
        repassword: yup.string().label('confirm password').required('konfirmasi password wajib diisi').oneOf([yup.ref('password'), null], 'Password tidak cocok'),
      }).required();

    const {register, handleSubmit, formState: {errors} } = useForm({
        resolver : yupResolver(schema)
    })

    const onSubmit = (e) => {
        setButton({
            textLogin:'Processing..',
            disabledBtn:true
        })
        let formData = new FormData()
        formData.append('password',e.password)
        formData.append('token',searchParams.get('token'))
        API_URL.membershipUrl.post('/reset-password',formData)
        .then(response => {
            setAlert({status:true, message:response.data.message })
            setButton({
                textLogin:'Ubah Password',
                disabledBtn:false
            })
        })
    }

    return (
       <div className="row justify-content-center">
            <div className="col-md-6 slide-up">
                <center><img src={logo} className='img-login' alt='images'/></center>
                <form onSubmit={handleSubmit(onSubmit)} className="form-login">
                    <h1>Ubah Password</h1>
                    <div className="inset">
                        {
                            alert.status === true ?
                            <div className="alert alert-primary" role="alert">
                                {alert.message}
                            </div>
                            :''
                        }
                        <div className='form-group mb-3'>
                            <input placeholder="Password" type='password' className={`form-control ${errors.password ? 'is-invalid' : ''} form-input`} {...register("password") } />
                            <span className='text-danger'>{errors.password?.message}</span>
                        </div>
                        <div className='form-group mb-3'>
                            <input type='password' placeholder="Konfirmasi Password" className={`form-control ${errors.repassword ? 'is-invalid' : ''} form-input`} {...register("repassword") } />
                            <span className='text-danger'>{errors.repassword?.message}</span>
                        </div>
                    </div>
                    <p className="p-container">
                        {/* <Link to='/login' className="clr-white">Back to Login ?</Link> */}
                        <button disabled={button.disabledBtn} type="submit" className="submit">{button.textLogin}</button>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword;