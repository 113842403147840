import Api from '../../Api'

const GET = (path) => {
    const result = new Promise ((resolve, reject) => {
        Api.membershipUrl.get(`${path}`)
        .then(response => {
             resolve(response.data.data)
        })
        .catch(err => {
            reject(err)
        })
    })
    return result
}

const POST = (path, formData) => {
    const result = new Promise ((resolve, reject) => {
        Api.membershipUrl.post(`${path}`,formData)
        .then(response => {
            resolve(response.data.data)
        })
        .catch(err => {
             reject(err)
        })
    })
    return result
}

const services = {
    GET,
    POST
}

export default services