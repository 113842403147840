import React, { Component } from "react";
import "./theme.scss";
import "./assets/css/themify-icons.css";
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";


import Index from "./components/index"
import Login from "./member-page/login"
import Logout from "./member-page/login/logout"
import ForgotPassword from "./member-page/forgot-password"
import ChangePassword from "./member-page/change-password"
import EmailVerified from "./member-page/email-verified";
import HomePage from "./member-page/home-page"
import Step from './member-page/home-page/step'
import { connect } from "react-redux"


class App extends Component {

  render(){
    return(
      <>
        <Router basename={`/`}>
            <Routes>
              <Route path="/" element={this.props.data.status_login === true ? <Navigate to='/home'/>: <Index/>} />
              {/* <Route path="/login" element={<Login/>} /> */}
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/reset-password" element={<ChangePassword/>} />
              <Route path="/verified-email" element={<EmailVerified/>} />
              {/* {
                this.props.data.status_login === true ?
                <>
                <Route path="/home" element={<HomePage/>} />
                <Route path="/step"  element={<Step/>}/>
                </>
                
                :
                <Route path="*" element={<Navigate to="/login" replace />} />
              }
              
              <Route path="/logout" element={<Logout/>} /> */}
            </Routes>
        </Router>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.auth.dataUser,
})

export default connect(mapStateToProps, null)(App)
