import React, { Component, Suspense } from 'react';

const NavbarPage = React.lazy(() => import('./Navbar'))
const Section = React.lazy(() => import('./section'))
const About  = React.lazy(() => import('./About'))
const PrioritasMember = React.lazy(() => import('./PrioritasMember'))
const Benefit = React.lazy(() => import('./PrioritasMember/benefit'))
const TalentMapping = React.lazy(() => import('./TalentMapping'))
const PricingMapping = React.lazy(() => import('./TalentMapping/PricingMapping'))
const Gallery = React.lazy(() => import('./Gallery'))
const Event = React.lazy(() => import('./Event'))
const FormRegister = React.lazy(() => import('./FormRegister'))
const Footer  = React.lazy(() => import('./Footer'))
const Transisi = React.lazy(() => import('./Transisi'))


class Index extends Component{
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                { id: 2 , idnm : "about", navheading: "Prioritas Member" },
                { id: 3 , idnm : "talent-mapping", navheading: "Talent Mapping" },
                { id: 4 , idnm : "gallery", navheading: "Gallery" },
                { id: 5 , idnm : "event", navheading: "Event" },

            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "darkheader", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render(){
        return(
            <>
                <Suspense fallback = {this.Loader()} >

                    {/* Importing Navbar */}
                    <NavbarPage navItems={this.state.navItems} navclassName={this.state.navClass}/>

                    {/* Importing section */}
                    <Section/>
                    <Transisi/>

                    {/* Importing about */}
                    <About/>

                    {/* Importing about */}
                    <PrioritasMember/>
                    <Transisi/>

                    <TalentMapping/>
                    <PricingMapping/>

                    <Benefit/>

                    <Gallery/>

                    <Event/>

                    {/* Importing Form */}
                    {/* <FormRegister/> */}

                    {/* Importing footer */}
                    <Footer/>
                </Suspense>
            </>
        )
    }
}


export default Index