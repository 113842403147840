import React, { useState } from "react"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import API_URL from "../../Api"

import '../login/login-style.css'
import logo from '../../assets/images/logo-black.png'


const ForgotPassword = () => {

    const[email,setEmail] = useState('')
    const[alert,setAlert] = useState(false)
    const[button, setButton] = useState({ textLogin:'Reset Password', disabledBtn:false})

    const handleSubmit = (e) => {
        e.preventDefault()
        setButton({
            textLogin:'Processing..',
            disabledBtn:true
        })
        let formData = new FormData()
        formData.append('email',email)
        API_URL.membershipUrl.post('/forgot-password',formData)
        .then(response => {
            if(response.data.data ===  true){
                Swal.fire({  
                    title: 'Sukses',
                    icon: 'success',   
                    type: 'success',
                    confirmButtonColor: '#3085d6',   
                    text: 'Link Reset Password sudah terkirim ke email anda',  
                  });
                setAlert(false)
            }
            else
                setAlert(true)
            setButton({
                textLogin:'Reset Password',
                disabledBtn:false
            })
        })
    }

    return (
       <div className="row justify-content-center">
            <div className="col-md-6 slide-up">
                <center><img src={logo} className='img-login' alt='images'/></center>
                <form onSubmit={handleSubmit} className="form-login">
                    <h1>Reset Password</h1>
                    <div className="inset">
                        {
                            alert === true ?
                            <div className="alert alert-danger" role="alert">
                                email tidak ditemukan
                            </div>
                            :''
                        }
                    <p>
                        <label className="label" htmlFor="email">EMAIL ADDRESS</label>
                        <input className="form-control form-input" onChange={e => setEmail(e.target.value)} value={email} type="text" name="email"/>
                    </p>
                    </div>
                    <p className="p-container">
                        {/* <Link to='/login' className="clr-white">Back to Login ?</Link> */}
                        <button disabled={button.disabledBtn} type="submit" className="submit">{button.textLogin}</button>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;